import React from 'react';

import BaseLayout from '../components/layouts/BaseLayout';
import { Section } from '../components/layouts/Containers';
import { P } from '../components/elements/Typography';
import Stage from '../components/layouts/Stage';
import StageIntro from '../components/elements/StageIntro';
import { Link } from '../components/elements/ButtonsAndLinks';

const ContactPage = () => (
    <BaseLayout title="Kontakt" description="Ansprechpartner zum LOTTO Sportjugend-Förderpreis">
        <Stage>
            <StageIntro headline={{ text: 'Kontakt', smaller: true }} />
        </Stage>
        <Section container="s">
            <P gap="l">
                <strong>Lisa Porada</strong>
                <br />
                Leitung Sportjugend
                <br />
                Baden-Württembergische Sportjugend im Landessportverband Baden-Württemberg e. V.
                <br />
                0711 28077-863
                <br />
                <Link to="mailto:l.porada@lsvbw.de" />
            </P>
            <P>
                <strong>Friedemann Häberlen</strong>
                <br />
                Staatliche Toto-Lotto GmbH Baden-Württemberg
                <br />
                0711 81000-112
                <br />
                <Link to="mailto:ZnJpZWRlbWFubi5oYWViZXJsZW4oYXQpbG90dG8tYncuZGU=" />
            </P>
        </Section>
    </BaseLayout>
);

export default ContactPage;
